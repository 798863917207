export enum Ability {
  DEFAULT = "DEFAULT",
  FIRE_BLAST = "FIRE_BLAST",
  WHEEL_OF_FIRE = "WHEEL_OF_FIRE",
  SEISMIC_TOSS = "SEISMIC_TOSS",
  GUILLOTINE = "GUILLOTINE",
  ROCK_SLIDE = "ROCK_SLIDE",
  HEAT_WAVE = "HEAT_WAVE",
  FLAMETHROWER = "FLAMETHROWER",
  THUNDER = "THUNDER",
  HYDRO_PUMP = "HYDRO_PUMP",
  DRACO_METEOR = "DRACO_METEOR",
  BLAZE_KICK = "BLAZE_KICK",
  WISH = "WISH",
  CALM_MIND = "CALM_MIND",
  DEFENSE_CURL = "DEFENSE_CURL",
  IRON_DEFENSE = "IRON_DEFENSE",
  METRONOME = "METRONOME",
  SOAK = "SOAK",
  IRON_TAIL = "IRON_TAIL",
  BLAST_BURN = "BLAST_BURN",
  CHARGE = "CHARGE",
  DISCHARGE = "DISCHARGE",
  BITE = "BITE",
  DRAGON_TAIL = "DRAGON_TAIL",
  DRAGON_BREATH = "DRAGON_BREATH",
  ICICLE_CRASH = "ICICLE_CRASH",
  ROOT = "ROOT",
  TORMENT = "TORMENT",
  STOMP = "STOMP",
  PAYBACK = "PAYBACK",
  NIGHT_SLASH = "NIGHT_SLASH",
  KOWTOW_CLEAVE = "KOWTOW_CLEAVE",
  BUG_BUZZ = "BUG_BUZZ",
  STRING_SHOT = "STRING_SHOT",
  VENOSHOCK = "VENOSHOCK",
  LEECH_LIFE = "LEECH_LIFE",
  HAPPY_HOUR = "HAPPY_HOUR",
  TELEPORT = "TELEPORT",
  NASTY_PLOT = "NASTY_PLOT",
  THIEF = "THIEF",
  STUN_SPORE = "STUN_SPORE",
  METEOR_MASH = "METEOR_MASH",
  HURRICANE = "HURRICANE",
  BURN = "BURN",
  AURASPHERE = "AURASPHERE",
  SLEEP = "SLEEP",
  PROTECT = "PROTECT",
  POISON = "POISON",
  CONFUSION = "CONFUSION",
  ORIGIN_PULSE = "ORIGIN_PULSE",
  SEED_FLARE = "SEED_FLARE",
  HEAL_BLOCK = "HEAL_BLOCK",
  ROAR_OF_TIME = "ROAR_OF_TIME",
  ROCK_TOMB = "ROCK_TOMB",
  ROCK_SMASH = "ROCK_SMASH",
  HEAD_SMASH = "HEAD_SMASH",
  VOLT_SWITCH = "VOLT_SWITCH",
  SHADOW_CLONE = "SHADOW_CLONE",
  HYPER_VOICE = "HYPER_VOICE",
  PETAL_DANCE = "PETAL_DANCE",
  ECHO = "ECHO",
  TRI_ATTACK = "TRI_ATTACK",
  GRASS_WHISTLE = "GRASS_WHISTLE",
  HIGH_JUMP_KICK = "HIGH_JUMP_KICK",
  DISARMING_VOICE = "DISARMING_VOICE",
  FAIRY_WIND = "FAIRY_WIND",
  RELIC_SONG = "RELIC_SONG",
  U_TURN = "U_TURN",
  GROWL = "GROWL",
  BONEMERANG = "BONEMERANG",
  CLANGOROUS_SOUL = "CLANGOROUS_SOUL",
  NIGHTMARE = "NIGHTMARE",
  EXPLOSION = "EXPLOSION",
  KING_SHIELD = "KING_SHIELD",
  CRABHAMMER = "CRABHAMMER",
  DIAMOND_STORM = "DIAMOND_STORM",
  DRACO_ENERGY = "DRACO_ENERGY",
  DYNAMAX_CANNON = "DYNAMAX_CANNON",
  DYNAMIC_PUNCH = "DYNAMIC_PUNCH",
  ELECTRO_BOOST = "ELECTRO_BOOST",
  ELECTRO_WEB = "ELECTRO_WEB",
  FIRE_TRICK = "FIRE_TRICK",
  FLAME_CHARGE = "FLAME_CHARGE",
  LEECH_SEED = "LEECH_SEED",
  LOCK_ON = "LOCK_ON",
  PSYCH_UP = "PSYCH_UP",
  RAZOR_WIND = "RAZOR_WIND",
  TWISTING_NETHER = "TWISTING_NETHER",
  WONDER_GUARD = "WONDER_GUARD",
  SONG_OF_DESIRE = "SONG_OF_DESIRE",
  CONFUSING_MIND = "CONFUSING_MIND",
  KNOWLEDGE_THIEF = "KNOWLEDGE_THIEF",
  EARTHQUAKE = "EARTHQUAKE",
  SOFT_BOILED = "SOFT_BOILED",
  ELECTRIC_SURGE = "ELECTRIC_SURGE",
  PSYCHIC_SURGE = "PSYCHIC_SURGE",
  MIND_BLOWN = "MIND_BLOWN",
  PAYDAY = "PAYDAY",
  PICKUP = "PICKUP",
  BEAT_UP = "BEAT_UP",
  BLUE_FLARE = "BLUE_FLARE",
  FUSION_BOLT = "FUSION_BOLT",
  AURORA_VEIL = "AURORA_VEIL",
  AQUA_JET = "AQUA_JET",
  JUDGEMENT = "JUDGEMENT",
  CHATTER = "CHATTER",
  LIQUIDATION = "LIQUIDATION",
  STEAM_ERUPTION = "STEAM_ERUPTION",
  APPLE_ACID = "APPLE_ACID",
  SHADOW_BALL = "SHADOW_BALL",
  DIVE = "DIVE",
  SPIKE_ARMOR = "SPIKE_ARMOR",
  FUTURE_SIGHT = "FUTURE_SIGHT",
  FAKE_TEARS = "FAKE_TEARS",
  SPARKLING_ARIA = "SPARKLING_ARIA",
  DRAGON_DARTS = "DRAGON_DARTS",
  GRASSY_SURGE = "GRASSY_SURGE",
  MISTY_SURGE = "MISTY_SURGE",
  SKY_ATTACK = "SKY_ATTACK",
  SKY_ATTACK_SHADOW = "SKY_ATTACK_SHADOW",
  ILLUSION = "ILLUSION",
  SLUDGE = "SLUDGE",
  SLUDGE_WAVE = "SLUDGE_WAVE",
  AURORA_BEAM = "AURORA_BEAM",
  AGILITY = "AGILITY",
  SPIRIT_SHACKLE = "SPIRIT_SHACKLE",
  WATER_SHURIKEN = "WATER_SHURIKEN",
  SHADOW_SNEAK = "SHADOW_SNEAK",
  MACH_PUNCH = "MACH_PUNCH",
  UPPERCUT = "UPPERCUT",
  TRIPLE_KICK = "TRIPLE_KICK",
  MAWASHI_GERI = "MAWASHI_GERI",
  FORECAST = "FORECAST",
  SACRED_SWORD = "SACRED_SWORD",
  X_SCISSOR = "X_SCISSOR",
  PLASMA_FIST = "PLASMA_FIST",
  SPECTRAL_THIEF = "SPECTRAL_THIEF",
  GEOMANCY = "GEOMANCY",
  DEATH_WING = "DEATH_WING",
  SLACK_OFF = "SLACK_OFF",
  DARK_VOID = "DARK_VOID",
  OVERHEAT = "OVERHEAT",
  HYPNOSIS = "HYPNOSIS",
  MIMIC = "MIMIC",
  HEX = "HEX",
  GROWTH = "GROWTH",
  HEAL_ORDER = "HEAL_ORDER",
  SHELL_TRAP = "SHELL_TRAP",
  DIG = "DIG",
  FIRE_SPIN = "FIRE_SPIN",
  SEARING_SHOT = "SEARING_SHOT",
  PECK = "PECK",
  SPLASH = "SPLASH",
  COUNTER = "COUNTER",
  COSMIC_POWER = "COSMIC_POWER",
  ENTANGLING_THREAD = "ENTANGLING_THREAD",
  SILVER_WIND = "SILVER_WIND",
  POISON_POWDER = "POISON_POWDER",
  ICY_WIND = "ICY_WIND",
  GIGATON_HAMMER = "GIGATON_HAMMER",
  ACROBATICS = "ACROBATICS",
  ABSORB = "ABSORB",
  ROLLOUT = "ROLLOUT",
  THRASH = "THRASH",
  SOLAR_BEAM = "SOLAR_BEAM",
  MAGMA_STORM = "MAGMA_STORM",
  SLASHING_CLAW = "SLASHING_CLAW",
  ERUPTION = "ERUPTION",
  MIST_BALL = "MIST_BALL",
  LUSTER_PURGE = "LUSTER_PURGE",
  MUD_BUBBLE = "MUD_BUBBLE",
  LINK_CABLE = "LINK_CABLE",
  MAGIC_BOUNCE = "MAGIC_BOUNCE",
  HIDDEN_POWER_A = "HIDDEN_POWER_A",
  HIDDEN_POWER_B = "HIDDEN_POWER_B",
  HIDDEN_POWER_C = "HIDDEN_POWER_C",
  HIDDEN_POWER_D = "HIDDEN_POWER_D",
  HIDDEN_POWER_E = "HIDDEN_POWER_E",
  HIDDEN_POWER_F = "HIDDEN_POWER_F",
  HIDDEN_POWER_G = "HIDDEN_POWER_G",
  HIDDEN_POWER_H = "HIDDEN_POWER_H",
  HIDDEN_POWER_I = "HIDDEN_POWER_I",
  HIDDEN_POWER_J = "HIDDEN_POWER_J",
  HIDDEN_POWER_K = "HIDDEN_POWER_K",
  HIDDEN_POWER_L = "HIDDEN_POWER_L",
  HIDDEN_POWER_M = "HIDDEN_POWER_M",
  HIDDEN_POWER_N = "HIDDEN_POWER_N",
  HIDDEN_POWER_O = "HIDDEN_POWER_O",
  HIDDEN_POWER_P = "HIDDEN_POWER_P",
  HIDDEN_POWER_Q = "HIDDEN_POWER_Q",
  HIDDEN_POWER_R = "HIDDEN_POWER_R",
  HIDDEN_POWER_S = "HIDDEN_POWER_S",
  HIDDEN_POWER_T = "HIDDEN_POWER_T",
  HIDDEN_POWER_U = "HIDDEN_POWER_U",
  HIDDEN_POWER_V = "HIDDEN_POWER_V",
  HIDDEN_POWER_W = "HIDDEN_POWER_W",
  HIDDEN_POWER_X = "HIDDEN_POWER_X",
  HIDDEN_POWER_Y = "HIDDEN_POWER_Y",
  HIDDEN_POWER_Z = "HIDDEN_POWER_Z",
  HIDDEN_POWER_QM = "HIDDEN_POWER_QM",
  HIDDEN_POWER_EM = "HIDDEN_POWER_EM",
  POISON_JAB = "POISON_JAB",
  SHELL_SMASH = "SHELL_SMASH",
  HELPING_HAND = "HELPING_HAND",
  ASTRAL_BARRAGE = "ASTRAL_BARRAGE",
  BLIZZARD = "BLIZZARD",
  WATERFALL = "WATERFALL",
  PYRO_BALL = "PYRO_BALL",
  WHIRLPOOL = "WHIRLPOOL",
  SMOKE_SCREEN = "SMOKE_SCREEN",
  PRESENT = "PRESENT",
  LEAF_BLADE = "LEAF_BLADE",
  ANCHOR_SHOT = "ANCHOR_SHOT",
  SMOG = "SMOG",
  PSYCHIC = "PSYCHIC",
  PSYBEAM = "PSYBEAM",
  MAGNET_RISE = "MAGNET_RISE",
  ATTRACT = "ATTRACT",
  WATER_PULSE = "WATER_PULSE",
  PLAY_ROUGH = "PLAY_ROUGH",
  AERIAL_ACE = "AERIAL_ACE",
  PARABOLIC_CHARGE = "PARABOLIC_CHARGE",
  SUPER_FANG = "SUPER_FANG",
  TEETER_DANCE = "TEETER_DANCE",
  CLOSE_COMBAT = "CLOSE_COMBAT",
  ASSIST = "ASSIST",
  FISSURE = "FISSURE",
  ASSURANCE = "ASSURANCE",
  AQUA_RING = "AQUA_RING",
  POISON_GAS = "POISON_GAS",
  BRAVE_BIRD = "BRAVE_BIRD",
  MAGICAL_LEAF = "MAGICAL_LEAF",
  STEALTH_ROCKS = "STEALTH_ROCKS",
  STRUGGLE_BUG = "STRUGGLE_BUG",
  TAIL_GLOW = "TAIL_GLOW",
  PRISMATIC_LASER = "PRISMATIC_LASER",
  NATURAL_GIFT = "NATURAL_GIFT",
  NIGHT_SHADE = "NIGHT_SHADE",
  CHARGE_BEAM = "CHARGE_BEAM",
  POPULATION_BOMB = "POPULATION_BOMB",
  SCREECH = "SCREECH",
  SAND_TOMB = "SAND_TOMB",
  NUZZLE = "NUZZLE",
  WHIRLWIND = "WHIRLWIND",
  EMPTY_LIGHT = "EMPTY_LIGHT",
  UNBOUND = "UNBOUND",
  HYPERSPACE_FURY = "HYPERSPACE_FURY",
  SNIPE_SHOT = "SNIPE_SHOT",
  AIR_SLASH = "AIR_SLASH",
  EGGSPLOSION = "EGGSPLOSION",
  BODY_SLAM = "BODY_SLAM",
  FLORAL_HEALING = "FLORAL_HEALING",
  VINE_WHIP = "VINE_WHIP",
  BARB_BARRAGE = "BARB_BARRAGE",
  INFERNAL_PARADE = "INFERNAL_PARADE",
  MAGIC_POWDER = "MAGIC_POWDER",
  RETALIATE = "RETALIATE",
  SLASH = "SLASH",
  OUTRAGE = "OUTRAGE",
  LUNGE = "LUNGE",
  KNOCK_OFF = "KNOCK_OFF",
  FISHIOUS_REND = "FISHIOUS_REND",
  RECOVER = "RECOVER",
  CURSE = "CURSE",
  GOLD_RUSH = "GOLD_RUSH",
  MAKE_IT_RAIN = "MAKE_IT_RAIN",
  TIME_TRAVEL = "TIME_TRAVEL",
  POLTERGEIST = "POLTERGEIST",
  CRUSH_GRIP = "CRUSH_GRIP",
  SKETCH = "SKETCH",
  OVERDRIVE = "OVERDRIVE",
  LOVELY_KISS = "LOVELY_KISS",
  TRANSFORM = "TRANSFORM",
  PSYCHIC_FANGS = "PSYCHIC_FANGS",
  SHED_TAIL = "SHED_TAIL",
  SHIELDS_DOWN = "SHIELDS_DOWN",
  SHIELDS_UP = "SHIELDS_UP",
  SANDSEAR_STORM = "SANDSEAR_STORM",
  WILDBOLT_STORM = "WILDBOLT_STORM",
  BLEAKWIND_STORM = "BLEAKWIND_STORM",
  SPRINGTIDE_STORM = "SPRINGTIDE_STORM",
  AURA_WHEEL = "AURA_WHEEL",
  LICK = "LICK",
  FURY_SWIPES = "FURY_SWIPES",
  TICKLE = "TICKLE",
  AROMATHERAPY = "AROMATHERAPY",
  DETECT = "DETECT",
  SPACIAL_REND = "SPACIAL_REND",
  MULTI_ATTACK = "MULTI_ATTACK",
  STICKY_WEB = "STICKY_WEB",
  ACCELEROCK = "ACCELEROCK",
  PETAL_BLIZZARD = "PETAL_BLIZZARD",
  SUNSTEEL_STRIKE = "SUNSTEEL_STRIKE",
  MOONGEIST_BEAM = "MOONGEIST_BEAM",
  MANTIS_BLADES = "MANTIS_BLADES",
  FLEUR_CANNON = "FLEUR_CANNON",
  DOOM_DESIRE = "DOOM_DESIRE",
  SPIRIT_BREAK = "SPIRIT_BREAK",
  SHEER_COLD = "SHEER_COLD",
  PSYCHO_BOOST = "PSYCHO_BOOST",
  ZAP_CANNON = "ZAP_CANNON",
  EXTREME_SPEED = "EXTREME_SPEED",
  ICE_HAMMER = "ICE_HAMMER",
  POLLEN_PUFF = "POLLEN_PUFF",
  PSYSTRIKE = "PSYSTRIKE",
  FACADE = "FACADE",
  DREAM_EATER = "DREAM_EATER",
  SPARK = "SPARK",
  CRUNCH = "CRUNCH",
  CROSS_POISON = "CROSS_POISON",
  DOUBLE_EDGE = "DOUBLE_EDGE",
  SHELTER = "SHELTER",
  FIRE_FANG = "FIRE_FANG",
  ICE_FANG = "ICE_FANG",
  THUNDER_FANG = "THUNDER_FANG",
  TAIL_WHIP = "TAIL_WHIP",
  PSYSHIELD_BASH = "PSYSHIELD_BASH",
  QUIVER_DANCE = "QUIVER_DANCE",
  TORCH_SONG = "TORCH_SONG",
  POWER_WHIP = "POWER_WHIP",
  DARK_HARVEST = "DARK_HARVEST",
  PSYSHOCK = "PSYSHOCK",
  HEAVY_SLAM = "HEAVY_SLAM",
  AQUA_TAIL = "AQUA_TAIL",
  HAIL = "HAIL",
  RAPID_SPIN = "RAPID_SPIN",
  BOUNCE = "BOUNCE",
  GUNK_SHOT = "GUNK_SHOT",
  BLOOD_MOON = "BLOOD_MOON",
  TEA_TIME = "TEA_TIME",
  SPIKES = "SPIKES",
  SHADOW_PUNCH = "SHADOW_PUNCH",
  MAGNET_BOMB = "MAGNET_BOMB",
  MUDDY_WATER = "MUDDY_WATER",
  ANCIENT_POWER = "ANCIENT_POWER",
  MOON_DREAM = "MOON_DREAM",
  STONE_AXE = "STONE_AXE",
  CAMERA_FLASH = "CAMERA_FLASH",
  ROCK_HEAD = "ROCK_HEAD",
  FAIRY_LOCK = "FAIRY_LOCK",
  TAKE_HEART = "TAKE_HEART",
  LUNAR_BLESSING = "LUNAR_BLESSING",
  CRUSH_CLAW = "CRUSH_CLAW",
  FIRE_LASH = "FIRE_LASH",
  FLYING_PRESS = "FLYING_PRESS",
  DRAIN_PUNCH = "DRAIN_PUNCH",
  GRAVITY = "GRAVITY",
  DIRE_CLAW = "DIRE_CLAW",
  FAKE_OUT = "FAKE_OUT",
  PURIFY = "PURIFY",
  FELL_STINGER = "FELL_STINGER",
  GULP_MISSILE = "GULP_MISSILE",
  DOUBLE_SHOCK = "DOUBLE_SHOCK",
  SCHOOLING = "SCHOOLING",
  PASTEL_VEIL = "PASTEL_VEIL",
  CHARM = "CHARM",
  ENTRAINMENT = "ENTRAINMENT",
  OKTZOOKA = "OKTZOOKA",
  PSYCHO_SHIFT = "PSYCHO_SHIFT",
  GLAIVE_RUSH = "GLAIVE_RUSH",
  FOUL_PLAY = "FOUL_PLAY",
  DOUBLE_IRON_BASH = "DOUBLE_IRON_BASH",
  STONE_EDGE = "STONE_EDGE",
  ROAR = "ROAR",
  INFESTATION = "INFESTATION",
  IVY_CUDGEL = "IVY_CUDGEL",
  FORCE_PALM = "FORCE_PALM",
  METAL_BURST = "METAL_BURST",
  THUNDER_CAGE = "THUNDER_CAGE",
  HEADBUTT = "HEADBUTT",
  STEEL_WING = "STEEL_WING",
  YAWN = "YAWN",
  FIERY_DANCE = "FIERY_DANCE",
  BIDE = "BIDE",
  SHORE_UP = "SHORE_UP",
  POISON_STING = "POISON_STING",
  TRANSE = "TRANSE",
  GLACIATE = "GLACIATE",
  WOOD_HAMMER = "WOOD_HAMMER",
  TRICK_OR_TREAT = "TRICK_OR_TREAT",
  FREEZING_GLARE = "FREEZING_GLARE",
  THUNDEROUS_KICK = "THUNDEROUS_KICK",
  FIERY_WRATH = "FIERY_WRATH",
  VISE_GRIP = "VISE_GRIP",
  LAVA_PLUME = "LAVA_PLUME",
  BURN_UP = "BURN_UP",
  LANDS_WRATH = "LANDS_WRATH",
  THOUSAND_ARROWS = "THOUSAND_ARROWS",
  CORE_ENFORCER = "CORE_ENFORCER",
  POWER_HUG = "POWER_HUG",
  MORTAL_SPIN = "MORTAL_SPIN",
  METAL_CLAW = "METAL_CLAW"
}
