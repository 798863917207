export enum Status {
  BURN = "BURN",
  SILENCE = "SILENCE",
  FATIGUE = "FATIGUE",
  POISONNED = "POISONNED",
  FREEZE = "FREEZE",
  PROTECT = "PROTECT",
  SLEEP = "SLEEP",
  CONFUSION = "CONFUSION",
  CHARM = "CHARM",
  FLINCH = "FLINCH",
  WOUND = "WOUND",
  RAGE = "RAGE",
  RESURECTION = "RESURECTION",
  RESURECTING = "RESURECTING",
  PARALYSIS = "PARALYSIS",
  POKERUS = "POKERUS",
  ARMOR_REDUCTION = "ARMOR_REDUCTION",
  RUNE_PROTECT = "RUNE_PROTECT",
  CURSE = "CURSE",
  ELECTRIC_FIELD = "ELECTRIC_FIELD",
  PSYCHIC_FIELD = "PSYCHIC_FIELD",
  GRASS_FIELD = "GRASS_FIELD",
  FAIRY_FIELD = "FAIRY_FIELD",
  SPIKE_ARMOR = "SPIKE_ARMOR",
  MAGIC_BOUNCE = "MAGIC_BOUNCE",
  LOCKED = "LOCKED"
}
