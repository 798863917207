export enum Language {
  en = "en",
  fr = "fr",
  pt = "pt",
  de = "de",
  es = "es",
  it = "it",
  ja = "ja",
  nl = "nl",
  bg = "bg",
  ko = "ko",
  pl = "pl",
  vi = "vi",
  th = "th",
  zh = "zh"
}
